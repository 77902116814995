.root
  width: 100%
  padding: 0
  margin: 0
  background-image: linear-gradient(to top, #09203f 0%, #537895 100%)
  .wrapper
    display: flex
    align-items: center
    justify-content: center
    min-height: 100vh
    .loginWarning
        color: red
        text-align: center
    .loginBox
      width: 400px
      background: rgba(255, 255, 255, 0.4)
      padding: 3rem
      border-radius: 1rem
      h1
        text-align: center
        margin: 0 0 10px
        font-size: 1.8rem
        color: #081b35

    .googleButton
      background-color: #4285F4
      color: #fff
      border: none
      padding: 20px 20px
      font-size: 1.2rem
      cursor: pointer
      border-radius: 5px
      width: 100%
      &:hover
        background-color: #357ae8
